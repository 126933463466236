import { Box, SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { makeStyles } from "@placehires/react-component-library";
import classNames from "classnames";
import { GatsbyImage, GatsbyImageProps } from "gatsby-plugin-image";
import React from "react";

type BackgroundImageProps = GatsbyImageProps & {
  className?: string;
  fullHeight?: boolean;
  contentClassName?: string;
  children?: React.ReactNode;
  sx?: SxProps<Theme>;
};

const BackgroundImage: React.FC<BackgroundImageProps> = ({
  className,
  sx,
  contentClassName,
  fullHeight,
  children,
  ...gatsbyImageProps
}) => {
  const { classes } = useStyles();

  return (
    <Box
      className={classNames(
        classes.backgroundWrapper,
        { [classes.fullHeight]: fullHeight },
        className
      )}
      sx={sx}
    >
      <div className={classes.imgWrapper}>
        <GatsbyImage className={classes.img} {...gatsbyImageProps} />
      </div>
      <div
        className={classNames(classes.content, contentClassName, {
          [classes.fullHeight]: fullHeight
        })}
      >
        {children}
      </div>
    </Box>
  );
};

const useStyles = makeStyles()(() => ({
  backgroundWrapper: {
    position: "relative"
  },
  fullHeight: {
    height: "100%"
  },
  imgWrapper: {
    position: "absolute",
    width: "100%",
    height: "100%"
  },
  img: {
    width: "100%",
    height: "100%"
  },
  content: {
    position: "relative"
  }
}));

export default BackgroundImage;
