import { ArrowRightAlt } from "@mui/icons-material";
import { Button, Container } from "@mui/material";
import Grid from "@mui/material/Grid";
import { Box } from "@mui/system";
import { graphql, useStaticQuery } from "gatsby";
import { difference, uniq } from "lodash";
import React, { useMemo, useState } from "react";
import BlogPostCard from "../components/public/BlogPostCard";
import IntroSection from "../components/public/IntroSection";
import { noBackgroundWrapper } from "../constants/styles";
import PublicLayout from "../layout/PublicLayout";

const query = graphql`
  query {
    headerImage: file(relativePath: { eq: "blog.png" }) {
      ...fluidImage
    }

    allMdx(
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      nodes {
        slug
        body
        fields {
          frontmatter {
            author
            date
            summary
            title
            featuredImage {
              ...fluidImage
            }
          }
        }
      }
    }
  }
`;

const Blog = () => {
  const [showAll, setShowAll] = useState(false);
  const {
    allMdx: { nodes },
    headerImage
  } = useStaticQuery(query);

  const [featured, rest] = useMemo(() => {
    const allFeatured = nodes.filter((node: any) => node.fields.frontmatter.featuredPost);
    const featured = uniq(allFeatured.slice(0, 2).concat(nodes.slice(0, 2))).slice(0, 2);
    const rest = difference(nodes, featured);
    return [featured, rest];
  }, [nodes]);

  return (
    <PublicLayout seoTitle="Blog">
      <IntroSection image={headerImage.childImageSharp.gatsbyImageData} title="Blog" imageOverlay />
      <Container>
        <Box sx={noBackgroundWrapper()}>
          <Grid container spacing={3} mb={3}>
            {featured.map(({ slug, fields: { frontmatter }, body }: any) => (
              <Grid item xs={12} md={6} key={slug}>
                <BlogPostCard
                  image={frontmatter.featuredImage?.childImageSharp?.gatsbyImageData}
                  title={frontmatter.title}
                  author={frontmatter.author}
                  date={frontmatter.date}
                  summary={frontmatter.summary}
                  slug={slug}
                  content={body}
                  featured
                />
              </Grid>
            ))}
          </Grid>
          <Grid container spacing={3}>
            {rest
              .slice(0, showAll ? rest.length : 6)
              .map(({ slug, fields: { frontmatter }, body }: any) => (
                <Grid item xs={12} sm={6} md={4} key={slug}>
                  <BlogPostCard
                    image={frontmatter.featuredImage?.childImageSharp?.gatsbyImageData}
                    title={frontmatter.title}
                    author={frontmatter.author}
                    date={frontmatter.date}
                    summary={frontmatter.summary}
                    slug={slug}
                    content={body}
                  />
                </Grid>
              ))}
          </Grid>
          {rest.length > 6 && (
            <Box display="flex" justifyContent="center" mt={6}>
              <Button
                variant="outlined"
                color="primary"
                sx={{ mx: "auto" }}
                onClick={() => setShowAll(!showAll)}
              >
                {showAll ? "Less" : "More"} articles{" "}
                <ArrowRightAlt fontSize="small" sx={{ ml: 1 }} />
              </Button>
            </Box>
          )}
        </Box>
      </Container>
    </PublicLayout>
  );
};

export default Blog;
