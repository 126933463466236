import { Avatar, Chip } from "@mui/material";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import { makeStyles } from "@placehires/react-component-library";
import { format, isSameYear } from "date-fns";
import { Link } from "gatsby";
import { IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { multiLineTrim } from "../../constants/styles";
import BackgroundImage from "../BackgroundImage";

type BlogPostCardProps = {
  image: IGatsbyImageData;
  summary: string;
  title: string;
  author: string;
  date: string;
  slug: string;
  content: string;
  featured?: boolean;
};

const BlogPostCard: React.FC<BlogPostCardProps> = ({
  image,
  title,
  author,
  date,
  slug,
  featured
}) => {
  const { classes } = useStyles();
  const publishedDate = new Date(date);
  const dateFormat = `dd MMM${isSameYear(new Date(), publishedDate) ? "" : ", yyy'"}`;

  return (
    <Link to={`/${slug}`} className={classes.link}>
      <Card
        className={classes.card}
        sx={{
          minHeight: {
            md: featured ? 500 : "unset",
            sm: featured ? 300 : "unset"
          }
        }}
      >
        <BackgroundImage
          alt={`blog-${title}`}
          image={image}
          contentClassName={classes.cardContent}
          fullHeight
        >
          <div>
            <Chip
              label={featured ? "FEATURED" : ""}
              sx={{
                bgcolor: featured ? "white" : "transparent",
                color: "success.main",
                fontWeight: 600,
                fontSize: "12px",
                mb: "180px"
              }}
            />
          </div>
          <div>
            <Typography
              component="h2"
              sx={{
                color: "white",
                mb: featured ? 3 : 2,
                fontWeight: 500,
                ...multiLineTrim(2),
                fontSize: {
                  md: featured ? 28 : 20,
                  xs: featured ? 24 : 18
                }
              }}
            >
              {title}
            </Typography>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <Box display="flex" color="white">
                <Avatar sx={{ height: 40, width: 40, mr: 2 }} />
                <Box display="flex" flexDirection="column" justifyContent="center">
                  <Typography color="white" variant="h6" lineHeight={1}>
                    {author}
                  </Typography>
                  {/* Verified writer */}
                  {/*<Box display="flex" alignItems="center" color="white" mt="0.5rem">*/}
                  {/*  <CheckCircle fontSize="small" color="success" sx={{ mr: "0.5rem" }} />*/}
                  {/*  <Typography variant="caption" color="inherit">*/}
                  {/*    <i>Verified writer</i>*/}
                  {/*  </Typography>*/}
                  {/*</Box>*/}
                </Box>
              </Box>
              <Typography className={classes.date}>{format(new Date(date), dateFormat)}</Typography>
            </Box>
          </div>
        </BackgroundImage>
      </Card>
    </Link>
  );
};

const useStyles = makeStyles()((theme) => ({
  link: {
    display: "block",
    textDecoration: "none",
    height: "100%"
  },
  card: {
    height: "100%",
    borderRadius: "8px"
  },
  cardContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    background:
      "linear-gradient(180deg, rgba(24, 59, 86, 0.0001) 0%, rgba(22, 49, 70, 0.453847) 72.92%, #152532 100%)",
    color: "primary.contrastText",
    "&:hover": {
      boxShadow: theme.shadows[8]
    },
    padding: theme.spacing(2),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(4)
    }
  },
  date: {
    color: "#959EAD",
    lineHeight: 1,
    marginLeft: theme.spacing(2)
  }
}));

export default BlogPostCard;
